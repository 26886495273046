import React, { useEffect, useState } from "react";
import {
    DashBoardBodyContainerColumn,
    DashboardContainer,
    DashboardTopBarWrapper,
    DashboardWrapper,
} from "./Style";
import { DashboardTopBar } from "./Sections/TopBar";
import {
    ArcElement,
    BarElement,
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LinearScale,
    LineElement,
    PointElement,
    Title,
    Tooltip,
} from "chart.js";
import { DashboardBody } from "./Sections/Body";
import { useSearchParams } from "react-router-dom";
import { overviewStats, validateDocusignOAuth } from "../../Admin/Stats";
import { colorRaw } from "../../styles/mixins";
import { Button } from "../../../components/ui/button";

export function AdminDashboardPage() {
    const [stats, setStats] = useState<any>([]);
    const [caseFileData, setCaseFileData] = useState<any>({});
    const [accountsData, setAccountsData] = useState<any>({});
    const [revenueData, setRevenueData] = useState<any>({});
    const [caseFileTypeData, setCaseFileTypeData] = useState<any>({});

    const [queryParams] = useSearchParams();

    useEffect(() => {
        getData();

        const code = queryParams.get("code");

        if (code) {
            validateDocusignOAuth(code);
        }
    }, []);

    function getData() {
        overviewStats().then((r) => setStats(r));
    }

    useEffect(() => {
        if (stats) {
            initStats();
        }
    }, [stats]);

    function initStats() {
        const labels = stats.labels;

        setCaseFileData({
            labels,
            datasets: [
                {
                    label: "Case files with finalized documents",
                    data: stats.case_file_finalized_count,
                    borderColor: colorRaw("caseFiles")(),
                    backgroundColor: colorRaw("caseFiles")(),
                },
                {
                    label: "Case files opened",
                    data: stats.total_case_files,
                    borderColor: colorRaw("caseFilesOpened")(),
                    backgroundColor: colorRaw("caseFilesOpened")(),
                },
            ],
        });
        setAccountsData({
            labels,
            datasets: [
                {
                    label: "Accounts created",
                    data: stats.tenants_created,
                    borderColor: colorRaw("tenantsCreated")(),
                    backgroundColor: colorRaw("tenantsCreated")(),
                },
                {
                    label: "Users joined",
                    data: stats.user_joined,
                    borderColor: colorRaw("usersJoined")(),
                    backgroundColor: colorRaw("usersJoined")(),
                },
            ],
        });

        setRevenueData({
            labels,
            datasets: [
                {
                    label: "Copyright fees €",
                    data: stats.copyright_revenue,
                    borderColor: colorRaw("copyrightRevenue")(),
                    backgroundColor: colorRaw("copyrightRevenue")(),
                },
                {
                    label: "Service fees €",
                    data: stats.service_revenue,
                    borderColor: colorRaw("serviceRevenue")(),
                    backgroundColor: colorRaw("serviceRevenue")(),
                },
                {
                    label: "Total revenue €",
                    data: stats.total_revenue,
                    borderColor: colorRaw("totalRevenueBorder")(),
                    backgroundColor: colorRaw("totalRevenue")(),
                },
            ],
        });

        const pieLabel = stats?.case_files?.labels;

        setCaseFileTypeData({
            labels: pieLabel,
            datasets: [
                {
                    label: "# of type",
                    data: stats?.case_files?.data,
                    backgroundColor: [
                        colorRaw("primary")(),
                        colorRaw("blurple")(),
                        colorRaw("navy")(),
                        colorRaw("cobaltBlue")(),
                    ],
                },
            ],
        });

        ChartJS.register(
            CategoryScale,
            LinearScale,
            PointElement,
            LineElement,
            BarElement,
            Title,
            Tooltip,
            Legend,
            ArcElement,
        );
    }

    function requestInvites() {
        const redirect_url = window.location.origin;

        const base_url =
            process.env.MIX_APP_ENV === "production" && process.env.MIX_APP_DEBUG === "false"
                ? "account.docusign.com"
                : "account-d.docusign.com";

        window.location.href = `
            https://${base_url}/oauth/auth?
                response_type=code
                &scope=signature
                &client_id=${process.env.MIX_DOCUSIGN_INTEGRATION_KEY}
                &redirect_uri=${redirect_url}/dsredirect&state=***_***
        `;
    }

    return (
        <DashboardWrapper>
            <DashboardContainer>
                <DashboardTopBarWrapper>
                    <DashboardTopBar
                        caseFileData={caseFileData}
                        stats={stats}
                        caseFileTypeData={caseFileTypeData}
                    />
                </DashboardTopBarWrapper>
                <DashBoardBodyContainerColumn>
                    <DashboardBody accountsData={accountsData} revenueData={revenueData} />
                </DashBoardBodyContainerColumn>
            </DashboardContainer>
            <Button onClick={requestInvites}>docusign credentials</Button>
        </DashboardWrapper>
    );
}
